<template>
  <div class="text-center" style="padding: 50px">
    <b-skeleton animation="wave" height="15px"></b-skeleton>
    <div>Loading...</div>
  </div>
</template>

<script>
import {BSkeleton ,BProgress , BSpinner, BCardText, BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BSpinner,
    BCardText,
    BProgress,
    BSkeleton
  },
  data() {
    return {}
  },
}
</script>
